@media print {
  h1.headline {
    color: #000;
  }

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }

  #main-drawer {
    display: none;
  }

  #admin-layout.ui-drawer-closed > header,
  #admin-layout.ui-drawer-closed > main,
  #admin-layout.ui-drawer-open > header,
  #admin-layout.ui-drawer-open > main {
    padding-left: 0 !important;
  }

  button {
    display: none !important;
  }
}
