.reports-head-bar {
  display: flex;

  > div {
    flex-grow: 1;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  select {
    font-size: 13px;
  }

  h5 {
    margin: 0.5rem 0;
    font-size: 12px;
  }

  .buttons {
    text-align: right;

    > button {
      margin-left: 10px;
    }
  }
}

.graph-category {
  text-transform: uppercase;
  font-size: 1.2rem;
  border-bottom: 2px dashed orangered;

  &:not(.first) {
    page-break-before: always;
  }
}

.graphs-container {
  > div {
    margin: 1rem 0 2rem 0;
  }

  img {
    max-height: 80vh;
    max-width: 100%;
  }
}

@media (min-width: 2200px) {
  .graphs-container {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    > div {
      flex: 0 0 50%;
      justify-content: space-between;
    }
  }
}

@media print {
  .reports-head-bar {
    select,
    .MuiSelect-icon {
      display: none;
    }
  }
}
