@import "../colors.scss";
@import "./applications.scss";
@import "./employees.scss";
@import "./invitations.scss";
@import "./organizations.scss";
@import "./tables.scss";
@import "./pi.scss";
@import "./support.scss";

.headline {
  text-transform: uppercase;
}

span.current-market {
  position: relative;
  top: -7px;
  left: -7px;
}

@media (max-width: 700px) {
  span.current-market {
    display: none;
  }
}

#user-context {
  > .alert-warning {
    margin: 2rem;
    position: relative;
    z-index: 3000;
    border: 1px solid black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

#admin-layout {
  min-width: 320px;

  > header {
    z-index: 0;
    border-bottom: 1px solid $demant-blue;
  }

  .main-toolbar {
    display: flex;
    justify-content: space-between;
  }

  .drawer {
    .MuiListItemIcon-root {
      max-width: 40px;
    }
  }

  .drawer > div {
    overflow: hidden;
  }

  header,
  main,
  .drawer,
  .drawer > div {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .drawer-toggle-btn {
    text-align: center;
    padding-top: 3px;
    height: 55px;
  }

  &.ui-drawer-open {
    > header,
    > main {
      padding-left: 220px;
    }

    .drawer > div {
      width: 220px;
    }
  }

  .MuiListItem-button {
    min-height: 48px;
  }

  &.ui-drawer-closed {
    .MuiListItemText-root {
      display: none;
    }

    .drawer > div {
      width: 60px;
    }

    > header,
    > main {
      padding-left: 60px;
    }
  }

  #content-area {
    h1 {
      margin: 0 0 0.8em 0;
    }
  }

  .region {
    margin-top: 40px;
    border-top: 1px dotted #dedede;
  }

  .wrapper {
    padding: 20px;
  }
}

@media (min-width: 600px) {
  #admin-layout {
    .drawer-toggle-btn {
      height: 64px;
      padding-top: 8px;
    }
  }
}

.common-filters .preloader-mask {
  display: none;
}

.summary .edit-view {
  display: none;
}

.summary-text {
  padding-bottom: 1.2rem;
}

section {
  margin-bottom: 3rem;
}

dl.danger {
  dt {
    padding: 0;
    flex: 0 0 60%;
  }

  dd {
    flex: 0 0 20%;
  }

  .MuiCardContent-root {
    padding: 0;
  }
}

#main-menu {
  a .MuiListItemText-root > span {
    font-size: 0.9rem;
    white-space: nowrap;
  }
}
