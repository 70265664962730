.pagination-info {
  font-size: 0.8rem;
  font-style: italic;
  padding: 5px 0;

  > span {
    margin-right: 15px;
    position: relative;
    top: 2px;
  }
}
