.application-edit-form {
  dl {
    dt {
      flex: 0 0 30%;
    }
    dd {
      flex: 0 0 70%;
    }
  }
}
