.personal-field {
  &.anonymous {
    cursor: pointer;
  }

  button {
    display: none;
  }

  .preloader-mask.mini {
    display: inline;
    position: relative;
    top: -3px;
    margin-left: 5px;
  }

  .preloader-mask.mini .preloader-icon {
    width: 13px;
    height: 13px;
    min-height: 13px;
    display: inline;

    &:before {
      width: 13px !important;
      height: 13px !important;
      min-height: 13px !important;
    }
  }

  &:hover,
  &:focus {
    button {
      display: inline;
    }
  }
}
