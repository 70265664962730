h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

h2 span[title] {
  margin-left: 1rem;
  position: relative;
  top: 4px;
  vertical-align: middle;
  padding: 0;

  .MuiSvgIcon-root {
    font-size: 1.4rem;
  }
}
