.organization-edit-form {
  .checkboxs-wrapper {
    margin-left: -16px;
  }

  .single-checkbox-wrapper {
    margin-left: -11px;
  }

  .dashboard-and-linking-wrapper {
    padding-top: 1em;
  }
}

.organization-hcps-view {
  margin-bottom: 3rem;
}