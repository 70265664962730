.table-region {
  table,
  .table-children {
    margin-bottom: 1rem;
  }
}

.dynamic-table-region {
  position: relative;
}

.table-pagination {
  margin-top: 1rem;
  margin-left: -13px;
  margin-bottom: 5px;

  > div {
    justify-content: space-between;
    display: flex;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
  font-size: 1rem;
}

tr {
  border-bottom: 1px solid #ddd;
}

tbody tr {
  &:hover {
    background-color: #f7f7f7;
  }
}

.highlight-selected {
  tr {
    cursor: pointer;
    border-bottom: 1px solid #ddd;

    &.selected {
      background-color: gold;
    }
  }

  tbody tr {
    &:hover {
      &.selected {
        background-color: gold;
      }
    }
  }
}

th {
  text-align: left;
}

th,
td {
  padding: 5px 10px 5px 0;

  &:first-child {
    padding-left: 10px;
  }

  span {
    white-space: nowrap;
  }

  &.actions {
    text-align: right;
    white-space: nowrap;

    button {
      padding: 2px;
      width: 30px;
      min-width: auto;
      border-radius: 100%;
      border-color: #fff;
      outline: none;
      border: 1px solid transparent;

      &:hover,
      &:focus {
        border: 1px solid #eee;
      }
    }
  }

  &.status {
    text-align: left;
  }
}

.MuiTableCell-root {
  padding-left: 0 !important;
}

.no-results {
  margin-top: 2rem;
  display: block;
}
