button a {
  color: white;
  text-decoration: none;
}

a,
a button,
a span {
  text-decoration: none;
}

button {
  .fab {
    margin-right: 10px;
  }

  &.small {
    padding: 2px;
    width: 30px;
    min-width: auto;
    border-radius: 100%;
    border-color: #fff;
    outline: none;
    border: 1px solid;

    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.buttons-area {
  padding: 20px 0;

  button {
    margin-right: 3rem;
    margin-bottom: 10px;
  }
}

span.copy-to-clipboard {
  .MuiButton-root {
    min-width: auto !important;
  }
}

button.copy-to-clipboard {
  margin-left: 4px;
  width: 26px;
  border-radius: 10px;
  padding: 4px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
