@import "./texts.scss";
@import "./alerts.scss";
@import "./buttons.scss";
@import "./headings.scss";
@import "./loaders.scss";
@import "./errors.scss";
@import "./links.scss";
@import "./lists.scss";
@import "./tables.scss";
@import "./tabs.scss";
@import "./menus.scss";
@import "./forms.scss";
@import "./pagination.scss";
@import "./chips.scss";
@import "./dialogs.scss";
@import "./reports.scss";
@import "./admin/layout.scss";
@import "./themes/default.scss";
@import "./ie.scss";
@import "./print.scss";

body {
  overflow-y: scroll;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.relative {
  position: relative;
}
