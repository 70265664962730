.dialog {
  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.normal {
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 30vw;
    }
  }

  &.medium {
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 50vw;
    }
  }

  &.big {
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 80vw;
    }
  }

  &.full {
    .MuiDialogContent-root {
      overflow-y: hidden;
    }
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 100vw;
      height: 100vh;
    }
  }

  .table-wrapper {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 1.5rem;
  }

  dl {
    margin-top: 0;
  }
}

.dialog-single-button,
.dialog-buttons {
  margin: 0 15px 8px 15px;
}

.dialog-buttons {
  // space-between makes sure that Confirm and Cancel buttons
  // are as far from each other as possible.
  justify-content: space-between !important;
  align-items: end;
}

.MuiDialogContent-root .buttons-area {
  border-bottom: 1px dotted #ddd;
}
