.theme-default {
  .tabs {
    background: #17507f;
  }

  &.ui-impersonating {
    > header {
      border-bottom: 1px solid #b7328c !important;
    }

    .tabs {
      background: #542e42;

      .tab.selected {
        background: #973e6d;
      }

      .tab {
        &:after {
          border-bottom: 2px solid #ff00c4;
        }
      }

      .MuiButton-outlinedPrimary {
        border: 1px solid rgba(58, 32, 50, 0.5);
      }
    }
  }
}
