/* IE 10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #content-area {
    /* corrects top margin of sub-tabs: */
    position: absolute;
    margin-left: 60px;
    width: calc(100% - 60px);
  }

  .ui-drawer-open #content-area {
    /* drawer open: */
    margin-left: 220px;
    width: calc(100% - 220px);
  }
}
