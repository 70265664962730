.theme-default {
  .ui-menu-parent {
    ul {
      background: #175080;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    li {
      color: #fff;
    }

    li a {
      color: #fff;
    }

    li:hover,
    li:focus-within {
      background: #152554;
    }
  }

  &.ui-impersonating {
    .ui-menu-parent {
      ul {
        background: #541515;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }

      li {
        color: #fff;
      }

      li a {
        color: #fff;
      }

      li:hover,
      li:focus-within {
        background: #541515;
      }
    }
  }
}
