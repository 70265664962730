.approve-invitation-form {
  dl.inline {
    margin-top: 0;
  }
}

.template-preview-btn {
  button {
    margin-left: 30px;
    opacity: 0.5;

    .MuiSvgIcon-root {
      width: 0.8em;
    }

    &:hover {
      opacity: 1;
    }
  }
}

#bulk-invitations-region {
  td {
    .preloader-mask.mini {
      bottom: 15px;
      left: -12px;
    }

    .fa {
      font-size: 1.2rem;
    }
  }

  table {
    .ui-invalid {
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }
  }
}
