.theme-default {
  .drawer svg.MuiSvgIcon-root {
    color: #18507e; // #2589e0;
  }

  &.ui-impersonating {
    .drawer svg.MuiSvgIcon-root {
      color: #542e42;
    }
  }
}
