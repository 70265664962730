.theme-default {
  h2 span[title] {
    .MuiSvgIcon-root {
      color: #18507e;
    }
  }

  &.ui-impersonating {
    h2 span[title] {
      .MuiSvgIcon-root {
        color: #ab4881;
      }
    }
  }
}
